import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/wso2.png";

const wso = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>WSO2</h3>
            <div className="lbl-ptnrn">WSO2 Value Added Reseller</div>
            <div>
              <a
                href="https://wso2.com/partners/?c=Australia&p=&t="
                target="_blank"
              >
                Visit Website
              </a>{" "}
              <span></span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About WSO2</h4>
            <p>
              WSO2 offers an enterprise middleware product suite for API
              Management, Integration, Orchestration and Identity Management.
              Many large organizations such as Banks, Telcos, Airlines and
              Governments use WSO2 for managing their API services and customer
              identity management throughout the world. iTelaSoft provides
              consultation, solution design, and implementation of WSO2
              products.
            </p>
          </div>
          <div className="mt-3">
            <h4> Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>API Management</div>
            <div className="p"><span className="vdivide">-{" "}</span>Identity Management</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>
              Australia <span className="vdivide">|</span> Singapore{" "}
              <span className="vdivide">|</span> UK{" "}
              <span className="vdivide">|</span> EMEA
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default wso;
